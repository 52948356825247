export const r =  { // register
    title: '체크인 정보 입력',
    welcome: '환영합니다',
    name: '숙박자1 이름',
    n_placehoder: '성을 숙박자1 이름',
    surname: '이름',
    s_placehoder: '이름을 입력하세요',
    gender: '성별 선택',
    sexOptions: [{
        value: 'man',
        label: '남성'
    }, {
        value: 'woman',
        label: '여성'
    }],
    age: '나이',
    a_placehoder: '나이를 입력하세요',
    profession: '직업',
    pr_placehoder: '직업을 입력하세요',
    address: '주소',
    ad_placehoder: '주소를 입력하세요',
    passport: '여권 정보',
    pas_placehoder: '여권 정보를 입력해 주세요',
    p_check: '올바른 여권 정보를 입력해 주세요',
    mobile: '연락처',
    mobile_placehoder: '+82 123-4567-8910',
    card: '얼굴 사진을 업로드하세요',
    passport_i: '여권 사진을 업로드하세요',
    c_tip: '정면, 선명하고 가리지 않는 사진을 촬영해 주세요. 가리지 않는 경우 다시 요청할 수 있습니다.',
    add: '동반자 추가',
    agree: '동의',
    privacy: '《개인 정보 보호 정책》',
    p_content: '<div style="margin:8px;">    <p>존경하는 사용자님, 더 나은 서비스를 제공하기 위해 일부 개인 정보를 수집하고 사용할 필요가 있습니다. 제품이나 서비스를 이용하기 전에 아래 내용을 자세히 읽고 이해해 주시기 바랍니다:</p> <ol>      <li><b>정보 수집 범위:</b> 이름, 성별, 나이, 여권 번호, 여권 사진, 얼굴 사진, 휴대전화 번호 등 개인 정보를 수집합니다.</li>      <li><b>정보 사용 목적:</b> 제공된 개인 정보는 신원 확인, 관련 서비스 제공, 제품 기능 최적화, 사용자 경험 향상 등의 목적으로 사용됩니다.</li>      <li><b>정보 보호:</b> 엄격한 보안 조치를 취하여 개인 정보가 무단 액세스, 사용 또는 누출되지 않도록 보호합니다.</li>      <li><b>정보 공유:</b> 사용자의 명시적인 동의 없이는 개인 정보를 어떠한 제3자 기관이나 개인과도 공유하지 않습니다.</li>      <li><b>정보 저장:</b> 법률에 정한 기간 동안 개인 정보를 보관하고 필요하지 않을 때 안전하게 파기합니다.</li>      <li><b>개인 정보 권리:</b> 사용자는 자신의 개인 정보를 조회, 수정, 삭제할 수 있으며, 문의사항이나 의견이 있을 경우 저희 고객 서비스 팀에 문의하십시오.</li>    </ol>    <p>제품이나 서비스를 이용함으로써 개인 정보 보호 정책의 내용을 읽었고 동의한 것으로 간주됩니다. 질문이나 추가 도움이 필요한 경우 저희에게 연락해 주십시오.</p>    <p>저희를 믿고 지원해 주셔서 감사합니다!</p></div>',
    submit: "제출",
};

export const c =  { // check
    required: '필수 입력 항목입니다',
    name_20: '20자 이내로 입력해주세요',
    address_100: '100자 이내로 입력해주세요',
    age: '1에서 100 사이의 나이를 입력하세요',
    face: '얼굴 사진을 업로드하세요',
    passport: '여권 사진을 업로드하세요',
    privacy: '개인정보 보호정책을 먼저 읽으세요',
    alert: '제출 후에는 수정할 수 없습니다! 귀하의 정보가 사실인지, 정확한지 다시 한번 확인하시기 바랍니다.',
    alert_title: '힌트',
    confirmButtonText: '제출하다',
    cancelButtonText: '취소',
    alert_message: '정보가 맞는지 다시한번 확인해주세요!',
};

export const o =  { // checkout
    checkout: '체크아웃',
    n_checkout: '체크아웃 완료',
    main: '주요 손님',
    sub: '동반자',
    empty: '현재 체크아웃 정보가 없습니다',
    column: ['성', '이름', '유형', '조작']
};
