<template>
    <div class="home">
        <div class="container">
            <div class="row">
                <div class="col-12 welcome-text">
                    Welcome to Stay Cozy
                </div>
            </div>
            <div class="row btn-container">
                <div class="col-6">
                    <a v-for="(item, index) in registerList" :key="index"  @click="routerGo(item.key, 'register')" class="btn btn-light btn-lg btn-block">
                        {{ item.label }}
                    </a>
                </div>
                <div class="col-6">
                    <a v-for="(item, index) in checkoutList" :key="index" @click="routerGo(item.key, 'checkout')" class="btn btn-light btn-lg btn-block">
                        {{ item.label }}
                    </a>
                </div>
            </div>
        </div>
        <div>
            <div style="margin-top: 40px;">
                <a href="tel:+8109094563999" style="color: #ffffff;font-size: 20px;font-weight: 600;" >
                    <i class="fas fa-mobile-alt"></i> Call Me
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            registerList: [{ // register list
                label: 'Start to Check-in',
                key: 'en'
            },{
                label: 'チェックインする',
                key: 'ja'
            },{
                label: '办理入住手续',
                key: 'zh'
            },{
                label: '開始辦理手續',
                key: 'zhtc'
            },{
                label: '렛이즈 스타트 체크인',
                key: 'ko'
            }],
            checkoutList: [{ // checkout list
                label: 'Start Check-out',
                key: 'en'
            },{
                label: 'チェックアウトする',
                key: 'ja'
            },{
                label: '办理退房',
                key: 'zh'
            },{
                label: '辦理退房',
                key: 'zhtc'
            },{
                label: '체크아웃 시작하기',
                key: 'ko'
            }]
        };
    },
    methods: {
        routerGo(lang, path) { // register & checkout
            this.changeLang(lang);
            this.$router.push({ path: "/" + path });
        },
        changeLang(lan) { // change language
            this.$i18n.locale = lan;
            localStorage.setItem('language', lan);
        }
    }
};
</script>

<style lang="less" scoped>
.home {
	background-color: #2eacc9;
	height: 100%;
	text-align: center; 
}

.welcome-text {
	font-size: 60px; 
	margin-top: 15%; 
	font-weight: 600;
	color: #ffffff;
	text-shadow: 8px 2px 2px rgba(0, 0, 0, 0.5); 
}

.btn-container {
	margin-top: 40px; 
}

</style>