export const r =  { // register
    title: 'Check-In Information',
    welcome: 'Welcome',
    name: 'Surnme of guest',
    n_placehoder: 'Enter your Surnme of guest',
    surname: 'First name',
    s_placehoder: 'Enter your first name',
    gender: 'Select Gender',
    sexOptions: [{
        value: 'man',
        label: 'Male'
    }, {
        value: 'woman',
        label: 'Female'
    }],
    age: 'Age',
    a_placehoder: 'Enter age',
    profession: 'Occupation',
    pr_placehoder: 'Enter Occupation',
    address: 'Address',
    ad_placehoder: 'Enter address',
    passport: 'Passport Number',
    pas_placehoder: 'Please fill in the passport information',
    p_check: 'Please enter correct passport information',
    mobile: 'Contact Number',
    mobile_placehoder: '+1 (123)456-7890',
    card: 'Upload Face Photo',
    passport_i: 'Upload Passport Photo',
    c_tip: 'Take a clear, unobstructed photo of the front. If unclear, we may request another photo.',
    add: 'Add Companion',
    agree: 'Agree',
    privacy: '《Privacy Policy》',
    p_content: '<div style="margin:8px;">    <p>Dear User, to provide better service, we need to collect and use some of your personal information. Before using our product or service, please read and understand the following:</p> <ol>      <li><b>Information Collection:</b> We will collect your name, gender, age, passport number, passport photo, face photo, mobile number, and other personal information.</li>      <li><b>Use of Information:</b> The personal information you provide will be used for identity verification, providing relevant services, optimizing product features, improving user experience, etc.</li>      <li><b>Information Protection:</b> We will take strict security measures to protect your personal information from unauthorized access, use, or disclosure.</li>      <li><b>Information Sharing:</b> Unless we obtain your explicit consent, we will not share your personal information with any third-party organizations or individuals.</li>      <li><b>Information Storage:</b> We will store your personal information for the period prescribed by law and securely dispose of it when no longer needed.</li>      <li><b>Privacy Rights:</b> You have the right to request access, modification, or deletion of your personal information. If you have any questions or concerns, please contact our customer service team.</li>    </ol>    <p>By using our product or service, you indicate that you have read and agree to the contents of this privacy statement. If you have any questions or need further assistance, please contact us.</p>    <p>Thank you for your trust and support!</p></div>',
    submit: "Submit",
};

export const c =  { // check
    required: 'This field is required',
    name_20: 'Please keep within 20 characters',
    address_100: 'Please keep within 100 characters',
    age: 'Please enter an age between 1 and 100',
    face: 'Please upload a face photo',
    passport: 'Please upload a passport photo',
    privacy: 'Please read the privacy policy first',
    alert: 'It cannot be modified after submission! Please double-check whether your information is true and correct.',
    alert_title: 'Kind tips',
    confirmButtonText: 'Submit',
    cancelButtonText: 'Cancel',
    alert_message: 'Please double-check that the information is correct!',
};

export const o =  { // checkout
    checkout: 'Check-out',
    n_checkout: 'Checked out',
    main: 'Main Guest',
    sub: 'Companions',
    empty: 'No check-out information available at the moment',
    column: ['Surname', 'First Name', 'Type', 'Action']
};
