export const r =  { // register
    title: 'チェックイン情報入力',
    welcome: 'ようこそ',
    name: '氏名',
    n_placehoder: '氏名を入力してください',
    surname: 'かな',
    s_placehoder: 'かな',
    gender: '性別',
    sexOptions: [{
        value: 'man',
        label: '男性'
    }, {
        value: 'woman',
        label: '女性'
    }],
    age: '年齢',
    a_placehoder: '年齢を入力してください',
    profession: '職業',
    pr_placehoder: '職業を入力してください',
    address: '住所',
    ad_placehoder: '住所を入力してください',
    passport: 'パスポート情報',
    pas_placehoder: 'パスポート情報を入力してください',
    p_check: '正しいパスポート情報を入力してください',
    mobile: '連絡先',
    mobile_placehoder: '+81 12345678',
    card: '顔写真をアップロードしてください',
    passport_i: 'パスポートの写真をアップロードしてください',
    c_tip: '正面、クリアで隠れていない写真を撮影してください。見づらい場合は再度お願いすることがあります。',
    add: '同行者を追加する',
    agree: '同意する',
    privacy: '《プライバシーポリシー》',
    p_content: '<div style="margin:8px;">    <p>尊敬するユーザー様、より良いサービスを提供するため、一部個人情報の収集と利用が必要です。製品やサービスをご利用になる前に、以下の内容を詳しくお読みいただき、理解してください：</p> <ol>      <li><b>収集範囲：</b> お名前、性別、年齢、パスポート番号、パスポートの写真、顔写真、電話番号などの個人情報を収集します。</li>      <li><b>情報利用目的：</b> 提供された個人情報は、身元確認、関連サービスの提供、製品機能の最適化、ユーザーエクスペリエンスの向上などの目的で使用されます。</li>      <li><b>情報保護：</b> 厳格なセキュリティ対策を講じ、個人情報が未承認のアクセス、使用、漏洩されないように保護します。</li>      <li><b>情報共有：</b> ユーザーの明示的な同意がない限り、個人情報を第三者の機関や個人と共有しません。</li>      <li><b>情報保存：</b> 法律に定められた期間まで個人情報を保管し、不要になった時に安全に廃棄します。</li>      <li><b>プライバシー権利：</b> ユーザーは自身の個人情報を閲覧、修正、削除する権利があります。ご質問やご意見がある場合は、弊社のカスタマーサポートチームまでお問い合わせください。</li>    </ol>    <p>製品やサービスをご利用いただくことで、プライバシーポリシーの内容に同意したものとみなされます。ご質問や追加のサポートが必要な場合は、お気軽にお問い合わせください。</p>    <p>弊社を信頼していただき、ありがとうございます！</p></div>',
    submit: "提出",
};

export const c =  { // check
    required: 'この項目は必須です',
    name_20: '20文字以内で入力してください',
    address_100: '100文字以内で入力してください',
    age: '1から100までの年齢を入力してください',
    face: '顔写真をアップロードしてください',
    passport: 'パスポートの写真をアップロードしてください',
    privacy: 'まずプライバシーポリシーを読んでください',
    alert: '送信後の変更はできません。 あなたの情報が真実であるかどうかを再確認してください。',
    alert_title: 'ヒント',
    confirmButtonText: 'もちろん',
    cancelButtonText: 'キャンセル',
    alert_message: '情報が正しいことを再確認してください。',
};

export const o =  { // checkout
    checkout: 'チェックアウト',
    n_checkout: 'チェックアウト済み',
    main: 'メインゲスト',
    sub: '同行者',
    empty: '現在、チェックアウト情報はありません',
    column: ['姓', '名', 'タイプ', '操作']
};
