import Vue from 'vue'
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem('language') || 'zh',
  messages: {
    zh: require('./zh.js'),
    en: require('./en.js'),
    ko: require('./ko.js'),
    ja: require('./ja.js'),
    zhtc: require('./zhtc.js'),
  }
});