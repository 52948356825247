import Vue from 'vue'

import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';

import { 
    Dialog,
    Upload,
    Notification,
    Select, Option,
    Loading, Input, InputNumber, Form, FormItem, Table, TableColumn, Button,
    Dropdown, DropdownMenu, DropdownItem,
    Tag,
    Checkbox,
    MessageBox
} from 'element-ui';

Vue.use(Tag);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(Upload);
Vue.use(Form);
Vue.use(Select);
Vue.use(Option);
Vue.use(FormItem);
Vue.use(InputNumber);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Loading);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

Vue.prototype.$tip = Notification;
Vue.prototype.$confirm = MessageBox.confirm;