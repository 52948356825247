export const r =  { // register
    title: '入住資訊填寫',
    welcome: '歡迎入住',
    name: '姓氏',
    n_placehoder: '請輸入姓氏',
    surname: '名字',
    s_placehoder: '請輸入名字',
    gender: '選擇性別',
    sexOptions: [{
        value: 'man',
        label: '男性'
    }, {
        value: 'woman',
        label: '女性'
    }],
    age: '年齡',
    a_placehoder: '請輸入年齡',
    profession: '職業',
    pr_placehoder: '請輸入職業',
    address: '住址',
    ad_placehoder: '住址',
    passport: '護照資訊',
    pas_placehoder: '請填寫護照資訊',
    p_check: '請輸入正確的護照資訊',
    mobile: '聯絡電話',
    mobile_placehoder: '+886 0123-456-789',
    card: '請上傳人臉照片',
    passport_i: '請上傳護照照片',
    c_tip: '請拍攝正面、清晰、無遮擋的照片，若無法看清我們將再次請求您協助拍攝照片。',
    add: '追加同行人員',
    agree: '同意',
    privacy: '《隱私條款》',
    p_content: '<div style="margin:8px;">    <p>尊敬的使用者，為了提供更好的服務體驗，我們需要收集和使用您的部分個人資訊。在您使用我們的產品或服務之前，請務必詳細閱讀並理解以下內容：</p> <ol>      <li><b>收集資訊範圍：</b> 我們將收集您的姓名、性別、年齡、護照號碼、護照照片、人臉照片、手機號等個人資訊。</li>      <li><b>資訊用途：</b> 您提供的個人資訊將用於驗證身份、向您提供相關服務、優化產品功能、改善用戶體驗等目的。</li>      <li><b>資訊保護：</b> 我們將採取嚴格的安全措施，保護您的個人資訊不受未經授權的訪問、使用或洩露。</li>      <li><b>資訊共享：</b> 除非獲得您的明確同意，否則我們不會將您的個人資訊分享給任何第三方機構或個人。</li>      <li><b>資訊存儲：</b> 我們將按照法律規定的期限存儲您的個人資訊，並在不再需要時進行安全銷毀。</li>      <li><b>隱私權利：</b> 您有權要求查看、修改、刪除您的個人資訊，如有疑問或意見，請聯繫我們的客服團隊。</li>    </ol>    <p>通過使用我們的產品或服務，即表示您已閱讀並同意本隱私聲明的內容。如有任何疑問或需要進一步幫助，請與我們聯繫。</p>    <p>感謝您對我們的信任與支持！</p></div>',
    submit: "提交",
};

export const c =  { // check
    required: '此項為必填',
    name_20: '請保持在20個字符以內',
    address_100: '請保持在100個字符以內',
    age: '請輸入1-100之間的年齡',
    face: '請上傳人臉照片',
    passport: '請上傳護照照片',
    privacy: '請先閱讀隱私政策',
    alert: '提交後不能修改！ 請再次確認您的資訊是否真實正確。',
    alert_title: '提示',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    alert_message: '請再次檢查資訊是否正確！',
};

export const o =  { // checkout
    checkout: '退房',
    n_checkout: '已退房',
    main: '主要房客',
    sub: '同行人',
    empty: '目前暫無退房資訊',
    column: ['姓氏', '名字', '類型', '操作']
};
