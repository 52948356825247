export const r =  { // register
    title: '入住人员信息填写',
    welcome: '欢迎入住',
    name: '姓氏',
    n_placehoder: '请输入姓氏',
    surname: '名字',
    s_placehoder: '请输入名字',
    gender: '性别选择',
    sexOptions: [{
        value: 'man',
        label: '男性'
    }, {
        value: 'woman',
        label: '女性'
    }],
    age: '年龄',
    a_placehoder: '请输入年龄',
    profession: '职业',
    pr_placehoder: '请输入职业',
    address: '住所',
    ad_placehoder: '住所',
    passport: '护照信息',
    pas_placehoder: '请填写护照信息',
    p_check: '请输入正确的护照信息',
    mobile: '联系电话',
    mobile_placehoder: '+86 13112345678',
    card: '请上传人脸照片',
    passport_i: '请上传护照照片',
    c_tip: '请拍摄正面、清晰、无遮挡的照片，若无法看清我们将再次请求您协助拍摄照片。',
    add: '追加同行人员',
    agree: '同意',
    privacy: '《隐私条款》',
    p_content: '<div style="margin:8px;">    <p>尊敬的用户，为了提供更好的服务体验，我们需要收集和使用您的部分个人信息。在您使用我们的产品或服务之前，请务必详细阅读并理解以下内容：</p> <ol>      <li><b>收集信息范围：</b> 我们将收集您的姓名、性别、年龄、护照号码、护照照片、人脸照片、手机号等个人信息。</li>      <li><b>信息用途：</b> 您提供的个人信息将用于验证身份、向您提供相关服务、优化产品功能、改善用户体验等目的。</li>      <li><b>信息保护：</b> 我们将采取严格的安全措施，保护您的个人信息不受未经授权的访问、使用或泄露。</li>      <li><b>信息共享：</b> 除非获得您的明确同意，否则我们不会将您的个人信息分享给任何第三方机构或个人。</li>      <li><b>信息存储：</b> 我们将按照法律规定的期限存储您的个人信息，并在不再需要时进行安全销毁。</li>      <li><b>隐私权利：</b> 您有权要求查看、修改、删除您的个人信息，如有疑问或意见，请联系我们的客服团队。</li>    </ol>    <p>通过使用我们的产品或服务，即表示您已阅读并同意本隐私声明的内容。如有任何疑问或需要进一步帮助，请与我们联系。</p>    <p>感谢您对我们的信任与支持！</p></div>',
    submit: "提交",
};

export const c =  { // check
    required: '此项为必填',
    name_20: '请保持在20个字符以内',
    address_100: '请保持在100个字符以内',
    age: '请输入1-100之间的年龄',
    face: '请上传人脸照片',
    passport: '请上传护照照片',
    privacy: '请先阅读隐私政策',
    alert: '提交后不能修改！请再次确认您的信息是否真实正确。',
    alert_title: '提示',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    alert_message: '请再次检查信息是否正确！',
};

export const o =  { // checkout
    checkout: '退房',
    n_checkout: '已退房',
    main: '主人',
    sub: '同行人',
    empty: '暂时没查询到退房信息',
    column: ['姓氏', '名字', '类型', '操作']
};